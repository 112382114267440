import { Typography, Box, Grid2, Modal, Button } from '@mui/material'
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Navbar from './navbar'
import { useLocation } from 'react-router-dom';
import Mission from './mission';
import About from './about';
import Privacy from './privacy';
import Tutorials from './tutorials';
import TextTransition, { presets } from 'react-text-transition';

const promo_questions = [
    '"Who can I speak to about this?"',
    '"Who is responsible for that?"',
    '"Is there anyone who has worked on this before?"',
    '"Have we done this before?"',
    '"Do we have any customers like this?"',
    '"Who supplies that?"',
    '"Who has worked on this before?"',
    '"What have they worked on?"',
    '"Who can do that?"',
];


export default function Home() {
    const theme = useTheme();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    useEffect(() => {
        if (location.hash) {
            const elementId = location.hash.substring(1);
            const element = document.getElementById(elementId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <Box sx={{ 'backgroundColor': theme.palette.background.default }} id="home">

            <Navbar setOpen={setOpen} />
            <Box minHeight={'100vh'}>

                {/*Content*/
                }
                <Grid2 container sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    mt: '100px',
                    color: theme.palette.text.primary
                }}>
                    <Grid2 size={12} sx={{ padding: { xs: '10px', sm: '20px' }, textAlign: 'center' }}>
                        <Typography variant="h2" component="h2" sx={{
                            mb: 4,
                            fontSize: { xs: '2rem', sm: '3.75rem' }
                        }}>
                            Welcome to Org<span style={{ color: theme.palette.primary.main }}>Atlas</span>
                        </Typography>
                        <Typography variant="h6" component="h2" sx={{
                            fontSize: { xs: '1rem', sm: '1.25rem' }
                        }}>
                            Have you ever had the question...
                        </Typography>
                        <Typography variant="h4" component="h2"
                            sx={{
                                mb: 2,
                                color: theme.palette.primary.dark,
                                display: 'flex',
                                justifyContent: 'center',
                                fontSize: { xs: '16pt', sm: '20pt' }
                            }}>
                            <TextTransition springConfig={presets.wobbly}>{promo_questions[index % promo_questions.length]}</TextTransition>
                        </Typography>
                        <Typography variant="h6" component="h2" sx={{
                            maxWidth: '90%',
                            margin: '0 auto',
                            fontSize: { xs: '1rem', sm: '1.25rem' }
                        }}>
                            OrgAtlas creates a knowledge map for
                            your organisation, so that you can find the resources you need when you need them.
                        </Typography>
                        <Modal
                            open={open}
                            onClose={() => setOpen(false)}
                            aria-labelledby="book-demo-modal"
                            aria-describedby="book-a-demo-calendly"
                            closeAfterTransition
                            slotProps={{
                                backdrop: {
                                    sx: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }
                                }
                            }}
                        >
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '80%',
                                height: '80vh',
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 0,
                            }}>
                                <iframe
                                    src="https://calendly.com/daniel-orgatlas/30min"
                                    width="100%"
                                    height="100%"
                                />
                            </Box>
                        </Modal>
                        <Box sx={{
                            mt: 4,
                            display: 'flex',
                            gap: { xs: 1, sm: 2 },
                            justifyContent: 'center',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'center'
                        }}>
                            <Typography
                                variant="h5"
                                component="button"
                                sx={{
                                    color: theme.palette.text.primary,
                                    cursor: 'pointer',
                                    backgroundColor: theme.palette.primary.main,
                                    border: 'none',
                                    padding: { xs: '12px', sm: '16px' },
                                    borderRadius: '10px',
                                    fontSize: { xs: '1rem', sm: '1.5rem' },
                                    width: { xs: '80%', sm: 'auto' }
                                }}
                                onClick={() => setOpen(true)}
                            >
                                Book a Demo
                            </Typography>
                            <Typography
                                variant="h5"
                                component="button"
                                sx={{
                                    color: theme.palette.text.secondary,
                                    cursor: 'pointer',
                                    backgroundColor: theme.palette.secondary.main,
                                    border: 'none',
                                    padding: { xs: '12px', sm: '16px' },
                                    borderRadius: '10px',
                                    fontSize: { xs: '1rem', sm: '1.5rem' },
                                    width: { xs: '80%', sm: 'auto' }
                                }}
                                onClick={() => window.location.href = 'mailto:hello@orgatlas.org'}
                            >
                                Contact Sales
                            </Typography>
                        </Box>
                    </Grid2>
                </Grid2>
            </Box>
            <Mission />
            <About />
            <Privacy />
            <Tutorials />
        </Box>
    );
}