import { Box, Grid2, Typography, IconButton, Drawer, List, ListItem } from "@mui/material";
import { Link } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import OrgAtlas from '../assets/logo.png';
import { useEffect, useState } from "react";
// import MenuIcon from '@mui/icons-material/Menu';
import { FaBars } from 'react-icons/fa';

interface NavLinkProps {
    to: string;
    title: string;
    selected: string;
    setSelected: Function;
}

const NavLink = (props: NavLinkProps) => {
    const theme = useTheme();

    return (
        <Grid2 sx={{ marginLeft: '60px' }}>
            <Link
                to={props.to}
                style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color: props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary,
                    transition: 'color 0.8s',
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.color = theme.palette.primary.main;
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.color = props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary;
                }}
                onClick={() => {
                    props.setSelected(props.title)
                }}
            >
                <Typography variant="h4" fontSize="16pt">
                    {props.title}
                </Typography>
            </Link>
        </Grid2>
    );
};

const PageLink = (props: NavLinkProps) => {
    const theme = useTheme();

    return (
        <Grid2 sx={{ marginLeft: '60px' }}>
            <a
                href={props.to}
                style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color: props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary,
                    transition: 'color 0.8s',
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.color = theme.palette.primary.main;
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.color = props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary;
                }}
                onClick={() => {
                    props.setSelected(props.title)
                }}
            >
                <Typography variant="h4" fontSize="16pt">
                    {props.title}
                </Typography>
            </a>
        </Grid2>
    );
};

interface NavbarProps {
    setOpen: Function;
}

export default function Navbar(props: NavbarProps) {
    const [scrolled, setScrolled] = useState(false);
    const [selected, setSelected] = useState('Home');
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const menuItems = [
        { title: 'Home', to: '/#home', isPage: false },
        { title: 'Mission', to: '/#mission', isPage: true },
        { title: 'Privacy', to: '/#privacy', isPage: true },
        { title: 'Tutorials', to: '/#tutorials', isPage: false },
    ];

    const drawer = (
        <List>
            {menuItems.map((item) => (
                <ListItem key={item.title} sx={{ padding: '16px' }}>
                    {item.isPage ? (
                        <PageLink to={item.to} title={item.title} selected={selected} setSelected={setSelected} />
                    ) : (
                        <NavLink to={item.to} title={item.title} selected={selected} setSelected={setSelected} />
                    )}
                </ListItem>
            ))}
            <ListItem sx={{ padding: '16px' }}>
                <Typography
                    variant="h5"
                    component="button"
                    sx={{
                        width: '100%',
                        color: theme.palette.text.secondary,
                        cursor: 'pointer',
                        backgroundColor: theme.palette.secondary.main,
                        border: 'none',
                        padding: '8px 24px',
                        borderRadius: '10px',
                        fontSize: '16pt',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        }
                    }}
                    onClick={() => props.setOpen(true)}
                >
                    Book a Demo
                </Typography>
            </ListItem>
        </List>
    );

    return (
        <Grid2
            container
            sx={{
                padding: '20px',
                alignItems: 'center',
                position: 'sticky',
                top: 0,
                backgroundColor: theme.palette.background.default,
                boxShadow: scrolled ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : 'none',
                zIndex: 1000,
            }}
        >
            <Grid2 size={1}></Grid2>
            <Grid2 size={3}>
                <Box
                    sx={{
                        height: '80px',
                        maxWidth: '60%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '8px',
                    }}
                >
                    <img src={OrgAtlas} style={{ height: '100px' }} alt="OrgAtlas" />
                </Box>
            </Grid2>
            <Grid2 size={7} container sx={{
                justifyContent: 'flex-end',
                alignItems: 'center',
                display: { xs: 'none', md: 'flex' } // Hide on mobile
            }}>
                <NavLink to={'/#home'} title={'Home'} selected={selected} setSelected={setSelected} />
                <PageLink to={'/#mission'} title={'Mission'} selected={selected} setSelected={setSelected} ></PageLink>
                <PageLink to={'/#privacy'} title={'Privacy'} selected={selected} setSelected={setSelected} />
                <NavLink to={'/#tutorials'} title={'Tutorials'} selected={selected} setSelected={setSelected} />
                <Typography
                    variant="h5"
                    component="button"
                    sx={{
                        marginLeft: '60px',
                        color: theme.palette.text.secondary,
                        cursor: 'pointer',
                        backgroundColor: theme.palette.secondary.main,
                        border: 'none',
                        padding: '8px 24px',
                        borderRadius: '10px',
                        fontSize: '16pt',
                        transition: 'background-color 0.3s',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        }
                    }}
                    onClick={() => props.setOpen(true)}
                >
                    Book a Demo
                </Typography>
            </Grid2>
            <Grid2 sx={{
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'flex-end',
                flex: 1
            }}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                >
                    <FaBars />
                </IconButton>
            </Grid2>
            <Grid2 size={1}></Grid2>

            <Drawer
                variant="temporary"
                anchor="right"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better mobile performance
                }}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: 280,
                        backgroundColor: theme.palette.background.default,
                    },
                }}
            >
                {drawer}
            </Drawer>
        </Grid2>
    )
}