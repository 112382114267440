// import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import FourOhFour from "./components/404";
import Home from "./components/home";
import { ThemeProvider, CssBaseline } from '@mui/material'
import { theme } from './components/theme';

function App() {
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="*" element={<FourOhFour />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;
